import React, { useEffect, useState } from 'react';
import clientService from '../../../pages/service/clientService';

import NoImage from '../../../assets/img/no-image.png';
import { Link, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import OffWrap from '../../../components/Layout/Header/OffWrap';

import favIcon from '../../../assets/img/fav.png';
import Whatsapp from '../Whatsapp';

export default function FamilyVisa() {
  const [familylist, setFamilyList] = useState([]);
  const history = useHistory()

  const userData = JSON.parse(localStorage.getItem('userData'));

  const userId = userData?.id;

  useEffect(() => {
    const handlePopState = (event) => {
      const userConfirmed = window.confirm("Are you sure you want to go back?");
      if (!userConfirmed) {
        history.go(1); // Move forward in history stack to cancel back navigation
      }
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [history]);

  useEffect(() => {
    getfamilyVisa();
  }, []);

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem('userData'));
    console.log(user);
    if (!user) {
      //   window.
      history.push('/login');
    }
  }, []);

  const signOut = () => {
    if (window.confirm('Are you sure , Do you want to Logout ?')) {
      localStorage.removeItem('userData');
      history.push('/');
    }
  };



  const getfamilyVisa = async () => {
    try {
      console.log(userId);
      const data = await clientService.getAllFamily(userId);
      console.log(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      setFamilyList(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    } catch (error) {
      console.log(error);
    }

  };

  const deleteFamily = async (id) => {
    console.log(id)
    console.log(`deleted id ${id}`)
    const res = await clientService.deleteFamilyVisa(id)
    getfamilyVisa()
  }

  return (
    <React.Fragment>
      <Helmet>
        <link rel="icon" href={favIcon} />
      </Helmet>
      <OffWrap />
      <Whatsapp />

      <div className="rs-categories gray-bg style1 pt-40 pb-70 md-pt-64 md-pb-40">
        <div className="container">
          <div className="row y-middle mb-30 md-mb-30">
            <div className="col-md-6 sm-mb-30">
              <div
                className="rs-breadcrumbs breadcrumbs-overlay"
                style={{ color: 'black' }}
              >
                <div className="breadcrumbs-text black-color">
                  <ul>
                    <li>
                      <Link to="/dashboard" className="active">
                        <b>Home</b>
                      </Link>
                    </li>

                    <li>
                      <Link to="#" className="active">
                        <b>Family Visa</b>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="btn-part text-right sm-text-left">
                <div className="  relative text-end hidden-md">
                  <div className="expand-btn-inner search-icon">
                    <ul className="expand-items">
                      <li className="sidebarmenu-search"></li>
                      <li className="user-icon cart-inner no-border mini-cart-active">
                        <Link to="/my-account">
                          <i
                            className="fa fa-user-o"
                            aria-hidden="true"
                            style={{ fontSize: '20px' }}
                          ></i>
                        </Link>
                        <div className="woocommerce-mini-cart text-left">
                          <div className="cart-bottom-part">
                            {/* <ul className="cart-icon-product-list">
                              <li className="display-flex">
                                <div className="icon-cart">
                                  <Link to="#">
                                    <i className="fa fa-info-circle"></i>
                                  </Link>
                                </div>
                                <div className="product-info">
                                  <Link to="/about">About Us</Link>
                                </div>
                              </li>
                              <li className="display-flex">
                                <div className="icon-cart">
                                  <Link to="#">
                                    <i className="fa fa-user-circle"></i>
                                  </Link>
                                </div>
                                <div className="product-info">
                                  <Link to="/my-account">Profile</Link>
                                </div>
                              </li>
                            </ul> */}

                            <div className="cart-btn text-center">
                              <Link className="crt-btn btn1" to="/dashboard">
                                Home
                              </Link>
                              <Link
                                className="crt-btn btn2"
                                to="#"
                                onClick={(e) => signOut()}
                              >
                                Logout
                              </Link>
                            </div>
                          </div>
                        </div>
                      </li>

                      <li>
                        <a
                          // onClick={canvasMenuAdd}
                          id="nav-expander"
                          className="nav-expander"
                          href="#"
                        >
                          <span className="dot1"></span>
                          <span className="dot2"></span>
                          <span className="dot3"></span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="content white-bg pt-30">
              <div className="course-overview">
                <div className="inner-box">
                  <div
                    id="rs-blog"
                    className="rs-blog style1 modify1 pt-50 pb-100 md-pt-70 md-pb-70"
                  >
                    <div class="d-flex flex-row-reverse bd-highlight">
                      <div class="p-2 bd-highlight">
                        {' '}
                        {/* <Link
                          to={'/visa/family/request'}
                          className="align-right"
                        >
                          <button className="btn-custom">Create</button>
                        </Link> */}
                      </div>
                    </div>

                    <div className="container">
                      <div className="row">
                        <div className="col-lg-12">
                          <div
                            id="rs-about"
                            className="rs-about style1 pb-100 md-pb-70"
                          >
                            <div className="container">
                              <div className="row">
                                <div className="col-lg-12 order-last">
                                  <div className="notice-bord style1">
                                    {/* <h4 className="title">Family Visa</h4> */}
                                    <div className="d-flex align-items-center my-3" style={{ width: '100%' }}>
                                      <h4 className="title mb-0" style={{ flexBasis: '80%', flexGrow: 1, marginRight: 0 }}>Family Visa</h4>
                                      <Link to={'/visa/family/request'} style={{ flexBasis: '20%', flexGrow: 0 }}>
                                        <button className="btn btn-custom" style={{ width: '100%' }}>Create</button>
                                      </Link>
                                    </div>
                                    <ul>

                                      {
                                        familylist?.length === 0 ? (
                                          <>
                                            {/* <span style={{ alignItems: 'center' }}>
                                              <img
                                                src={NoImage}
                                                style={{ width: '25%' }}
                                                alt="No data available"
                                              ></img>
                                            </span> */}
                                            <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                              <img src={NoImage} style={{ width: '25%', marginRight: '10px' }} alt="No data available" />
                                            </span>
                                            <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>No data available</span>


                                          </>
                                        ) :
                                          familylist?.map((item, index) => {


                                            return (
                                              <li>
                                                <div class="d-flex bd-highlight">
                                                  <div class="p-2 flex-grow-1 bd-highlight">
                                                    <div className="date"></div>
                                                    <div className="desc ml-3">
                                                      Source : &nbsp;{' '}
                                                      {item?.source} <br />
                                                      Emirates{' '}
                                                      {item?.emirates?.name}
                                                      <br />
                                                      {/* <p> */}
                                                      Nationality:{' '}
                                                      {
                                                        item?.nationality
                                                          ?.name
                                                      }
                                                      {/* </p> */}<br />
                                                      <div className="status" style={{
                                                        color: item?.requestedStatus === 'requested' ? 'red' :
                                                          item?.requestedStatus === 'under process' ? 'orange' :
                                                            item?.requestedStatus === 'collected' ? 'orange' :
                                                              item?.requestedStatus === 'completed' ? 'green' :
                                                                'black' // Default color if none match
                                                      }}>
                                                        {item?.requestedStatus}
                                                      </div>
                                                    </div>
                                                  </div>

                                                  <div class="p-2 bd-highlight">
                                                    <div className="desc ml-3">
                                                      <div class="d-flex bd-highlight mb-3">
                                                        <div class="p-2 bd-highlight">
                                                          <Link
                                                            to={`/editfamilyvisa/${item.id}`}
                                                          >
                                                            <i
                                                              className="fa fa-edit"
                                                              aria-hidden="true"
                                                              style={{
                                                                fontSize:
                                                                  '25px',
                                                              }}
                                                            ></i>
                                                          </Link>
                                                        </div>
                                                        <div class="p-2 bd-highlight">
                                                          <i
                                                            onClick={() => {
                                                              if (
                                                                window.confirm(
                                                                  'Do you want to delete ?'
                                                                )
                                                              ) {
                                                                deleteFamily(
                                                                  item.id
                                                                );
                                                              }
                                                            }}
                                                            className="fa fa-trash"
                                                            aria-hidden="true"
                                                            style={{
                                                              fontSize: '25px',
                                                              color: '#bd4141',
                                                            }}
                                                          ></i>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </li>
                                            );
                                          })
                                      }
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
