import { db ,storage} from '../../firebase/config';
import { deleteObject, ref } from "firebase/storage";

import {
  getDocs,
  getDoc,
  collection,
  query,
  addDoc,
  deleteDoc,
  doc,
 
  where,
  updateDoc
} from 'firebase/firestore';
const nationalityCollectionRef = collection(db, 'nationality');
const visaCollectionRef = collection(db, 'visaPurpose');
const schencountryCollectionRef = collection(db, 'schengenCountry');
const visastatusCollectionRef = collection(db, 'visaStatus');
const schengenVisasCollectionRef = collection(db, 'schengenVisasReq');
const typeCollectionRef = collection(db, 'docType');
const typeAttestationCollectionRef = collection(db, 'attestationType')
const typeCertificationCollectionRef = collection(db, 'certificationType')
const certificateAttestationCollectionRef = collection(db, 'certificateAttestation')
const certificateCountryCollectionRef = collection(db, 'certificateCountry')
const emiratesCollectionRef = collection(db, 'emirates')
const familyVisaCollectionRef = collection(db, 'familyVisaReq')
const familyVisaCountryCollectionRef = collection(db, 'familyCountry')
const pricingCollectionRef = collection(db, 'attestationPricing');
const remCollectionRef = collection(db, 'docReminder');
const fieldsCollectionRef = collection(db, 'docFields');
const userCollectionRef = collection(db, 'user')



class CustomerDataService {

//delete firebase storage file

  deleteFileDocument = async (collectionName, docUrl, filePath) => {
    try {
      // Step 1: Delete the file from Firebase Storage
      const fileRef = ref(storage, filePath);
      await deleteObject(fileRef);
      console.log(`File ${filePath} deleted successfully`);
  
      // Step 2: Delete the document from Firestore
      const docRef = doc(db, collectionName, docUrl.split("/").pop());
      await deleteDoc(docRef);
      console.log(`Document ${docUrl} deleted successfully`);
    } catch (error) {
      console.error("Error deleting document or file:", error);
    }
  };



  addUser = (newDoc) => {
    return addDoc(userCollectionRef, newDoc);
  };

  // getUser=(uname,pass)=>{
  //   console.log(uname,pass)
  //   return getDocs(query(userCollectionRef),
  //   where('username','==',uname ),
  //   where('password','==',pass)
  //  )}

  getUser = async (uname, pass) => {
    console.log(uname, pass);
    const querySnapshot = await getDocs(
      query(userCollectionRef, where('username', '==', uname))
    );

    if (querySnapshot.empty) {
      // No user found with the provided username
      return { status: 404, message: 'User not found' };
    } else {
      // User found, check if the password matches
      const userData = querySnapshot.docs[0].data();
      const userId = querySnapshot.docs[0].id;
      if (userData.password === pass) {
        // Password matches, return user data
        return { status: 200, data: { ...userData, id: userId } };
      } else {
        // Password does not match
        return { status: 401, message: 'Incorrect password' };
      }
    }
  };

  // getCountryCode = async (name) => {
  //   console.log(name)
  //   const docRef = collection(db, 'nationality');

  //   const q = query(nationalityCollectionRef, where('_id', '==', name));
  //   const querySnapshot = await getDocs(q);

  //   return querySnapshot

  //   // let countryCode = '';
  //   // querySnapshot.forEach((doc) => {
  //   //   countryCode = doc.data().code;
  //   // });
  //   // return countryCode;

  // }


  getCountryCode = (id) => {
    // console.log(id);
    const cntycode = doc(db, 'nationality', id);
    return getDoc(cntycode);
  };

  getAllUser = () => {
    return getDocs(query(userCollectionRef));
  };


  getExpiryDoc = async () => {
    // Get the current date
    const currentDate = new Date();

    // Calculate the date exactly 15 days from today
    const expiryDate = new Date(currentDate);
    expiryDate.setDate(expiryDate.getDate() + 15);

    // Reference to the Firestore collection
    const docRef = collection(db, 'docReminder');

    // Query documents where the expiryDate is exactly 15 days from today
    const q = query(docRef, where('expiryDate', '==', expiryDate));

    // Get the documents
    const querySnapshot = await getDocs(q);

    // Extract data from the query snapshot
    const documents = [];
    querySnapshot.forEach(doc => {
      documents.push({ id: doc.id, ...doc.data() });
    });

    return documents;
  };



  getloggeduser = (id) => {
    // console.log(id);
    const user = doc(db, 'user', id);
    return getDoc(user);
  };



  getUserCompanyId = (id) => {
    console.log(id)
    return getDocs(
      query
        (userCollectionRef,
          where('isCompany', '==', true), where('companyid', '==', id)))
  }

  updateUser = async (id, updateUser) => {

    try {
      console.log(id, updateUser)

      const userRef = doc(db, 'user', id);


      await updateDoc(userRef, updateUser);
      console.log("User updated successfully");
      return "User updated successfully";
    } catch (error) {
      console.error("Error updating user:", error);
    }
  }




  getUsersByCompanyId = (id) => {
    console.log(id)

    return getDocs(
      query
        (userCollectionRef,
          where('isCompany', '==', false), where('companyid', '==', id)))
  }


  getAllFamily = (userId) => {
    console.log(userId);
    return getDocs(
      query(familyVisaCollectionRef, where('user.userId', '==', userId))
    );
  };

  getFamilyOne = (id) => {
    console.log(id);
    const family = doc(db, 'familyVisaReq', id);
    return getDoc(family);
  };


  updateFamily = (id, updateFamily) => {
    try {
      console.log(updateFamily)
      console.log(updateFamily)
      const family = doc(db, 'familyVisaReq', id);

      return updateDoc(family, updateFamily);
    } catch (error) {
      console.log(error);
    }
  };




  deleteFamilyVisa = (id) => {
    const family = doc(db, 'familyVisaReq', id);
    return deleteDoc(family);
  };

  getAllRem = () => {
    return getDocs(query(remCollectionRef));
  };

  getDocReminderOne = (id) => {
    console.log(id);
    const docReminder = doc(db, 'docReminder', id);
    return getDoc(docReminder);
  };

  getsingleDocRem = (userID) => {
    console.log(userID);

    return getDocs(query(remCollectionRef, where('user.userId', '==', userID)));
  };

  deleteRemainder = (id) => {
    const rem = doc(db, 'docReminder', id);
    return deleteDoc(rem);
  };

  updateDocReminder = (id, data) => {
    try {
      const typeDoc = doc(db, 'docReminder', id);

      return updateDoc(typeDoc, data);
    } catch (error) {
      console.log(error);
    }
  };

  updateDocRemStatus = (id, data) => {
    try {
      console.log(id);
      console.log(data);
      const typeDoc = doc(db, 'docReminder', id);

      return updateDoc(typeDoc, data);
    } catch (error) {
      console.log(error);
    }
  };


  //document notification
  ////////////////////////////

  // checkExpirations = async () => {
  //   const now = Timestamp.now();
  //   const fifteenDaysFromNow = Timestamp.fromDate(new Date(now.toDate().getTime() + 15 * 24 * 60 * 60 * 1000));

  //   const remCollectionRef = db.collection('reminders');
  //   const q = query(remCollectionRef, where('ExpiryDate', '<=', fifteenDaysFromNow));
  //   const querySnapshot = await getDocs(q);

  //   let expiringItems = [];
  //   querySnapshot.forEach((doc) => {
  //     const data = doc.data();
  //     const expiryDate = data.ExpiryDate.toDate();
  //     const today = new Date();
  //     const daysToExpire = Math.ceil((expiryDate - today) / (1000 * 60 * 60 * 24));
  //     if (daysToExpire <= 15) {
  //       expiringItems.push({ ...data, id: doc.id });
  //     }
  //   });

  //   return expiringItems;
  // };




  addDocFields = (newDoc) => {
    // console.log("fields",newDoc)
    return addDoc(fieldsCollectionRef, newDoc);
  };

  getAllFields = (selectedvalue) => {
    console.log(selectedvalue);
    return getDocs(
      query(
        fieldsCollectionRef
        // where('docType'==selectedvalue)
      )
    );
  };

  getEmirates = () => {
    return getDocs(query(emiratesCollectionRef));
  };

  getfamilyCountry = () => {
    return getDocs(query(familyVisaCountryCollectionRef));
  };

  getCertiCountry = () => {
    return getDocs(query(certificateCountryCollectionRef));
  };

  getNationalityAll = () => {
    return getDocs(query(nationalityCollectionRef));
  };

  getAllVisa = () => {
    return getDocs(query(visaCollectionRef));
  };

  getShenCountry = () => {
    return getDocs(query(schencountryCollectionRef));
  };

  getVisaStatus = () => {
    return getDocs(query(visastatusCollectionRef));
  };

  addSchengenVisa = (newDoc) => {
    return addDoc(schengenVisasCollectionRef, newDoc);
  };

  getschengenVisasAll = (userID) => {
    // console.log(userID);
    return getDocs(
      query(schengenVisasCollectionRef, where('user.userId', '==', userID))
    );
  };

  // getschengenOne = (id) => {
  //   console.log(id);
  //   const schengen = doc(db, 'schengenVisasReq', id)
  //   return getDoc(schengen)
  // };

  getschengenOne = (id) => {
    const schengen = doc(db, 'schengenVisasReq', id);
    return getDoc(schengen);
  };

  updateSchenegen = (id, updateSchengen) => {
    try {
      const typeDoc = doc(db, 'schengenVisasReq', id);

      return updateDoc(typeDoc, updateSchengen);
    } catch (error) {
      console.log(error);
    }
  };

  deleteSchengen = (id) => {
    const schengen = doc(db, 'schengenVisasReq', id);
    return deleteDoc(schengen);
  };

  getAllType = () => {
    return getDocs(query(typeCollectionRef));
  };



  updateUserReq = async (id, updateUser) => {

    try {
      console.log(id, updateUser)

      const userRef = doc(db, 'user', id);


      await updateDoc(userRef, updateUser);
      console.log("User updated successfully");
      return "User updated successfully";
    } catch (error) {
      console.error("Error updating user:", error);
    }
  }

  getAllAttest = () => {
    return getDocs(query(typeAttestationCollectionRef));
  };

  getAllCertiType = () => {
    return getDocs(query(typeCertificationCollectionRef));
  };

  addCertificateAttest = (newDoc) => {
    return addDoc(certificateAttestationCollectionRef, newDoc);
  };

  getAllcertificateAttestation = (userID) => {
    console.log(userID);
    return getDocs(
      query(
        certificateAttestationCollectionRef,
        where('user.userId', '==', userID)
      )
    );
  };

  getAttestOne = (id) => {
    const attest = doc(db, 'certificateAttestation', id);
    return getDoc(attest);
  };

  updateAttest = (id, updateAttest) => {
    try {
      const attest = doc(db, 'certificateAttestation', id);

      return updateDoc(attest, updateAttest);
    } catch (error) {
      console.log(error);
    }
  };

  deleteAttest = (id) => {
    const attest = doc(db, 'certificateAttestation', id);
    return deleteDoc(attest);
  };

  getPricing = (nationId, attestId, certiId) => {
    // console.log("service",nationId,"attes",attestId,certiId)
    console.log('nation', nationId);
    console.log('attest', attestId);
    console.log('certi', certiId);
    return getDocs(
      query(
        pricingCollectionRef,
        where('certificateNation', '==', nationId), // ok
        where('attestationType', '==', attestId),
        where('certificateType', '==', certiId)
      )
    );
  };
}
export default new CustomerDataService()