import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';

// Image
import favIcon from '../../../assets/img/applogo.png';
import Logo from '../../../assets/img/logo/dark-logo.png';
import SiteBreadcrumb from '../../../components/Common/Breadcumb';

import HeaderStyleSix from '../../../components/Layout/Header/HeaderStyleSix';
import OffWrap from '../../../components/Layout/Header/OffWrap';

import bannerbg from '../../../assets/img/breadcrumbs/6.jpg';
import Footer from '../../../components/Layout/Footer/Footer';
import mobileLogo from '../../../assets/img/logo/logo-green.png';
import Whatsapp from '../Whatsapp';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from 'react-accessible-accordion';
import CountryList from './CountryList';
import { Timestamp } from 'firebase/firestore';
import clientService from '../../service/clientService';
import { addDoc, collection } from 'firebase/firestore';
import { db, storage } from '../../../firebase/config';

import {
  ref,
  uploadBytes,
  getDownloadURL,
  uploadBytesResumable,
} from 'firebase/storage';
import { Form, Button, FormSelect } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Link, useHistory } from 'react-router-dom';

import imageCompression from 'browser-image-compression';


export default function Index() {


  useEffect(() => {
    let user = JSON.parse(localStorage.getItem('userData'));
    console.log(user);
    if (!user) {
      //   window.
      history.push('/login');
    }
  }, []);

  const signOut = () => {
    if (window.confirm('Are you sure , Do you want to Logout ?')) {
      localStorage.removeItem('userData');
      history.push('/');
    }
  };

  const familyVisaCollectionRef = collection(db, 'familyVisaReq')

  const [nation, setNation] = useState([])
  const [nationselect, setNationSelect] = useState('')
  const [emirate, setEmirate] = useState([])
  const [emirateselect, setEmirateSelect] = useState('')
  const [number, setNumber] = useState()
  const [contact, setContact] = useState()

  const [location, setLocation] = useState(null);
  const [reqCheck, setReqCheck] = useState(null)

  const [loadingDoc1, setLoadingDoc1] = useState(false);
  const [doc1, setDoc1] = useState(null);
  const [doc1Name, setDoc1Name] = useState('');

  const [loadingDoc2, setLoadingDoc2] = useState(false);
  const [doc2, setDoc2] = useState(null);
  const [doc2Name, setDoc2Name] = useState('');

  const [loadingDoc3, setLoadingDoc3] = useState(false);
  const [doc3, setDoc3] = useState(null);
  const [doc3Name, setDoc3Name] = useState('');

  const [loadingDoc4, setLoadingDoc4] = useState(false);
  const [doc4, setDoc4] = useState(null);
  const [doc4Name, setDoc4Name] = useState('');

  const [loadingDoc5, setLoadingDoc5] = useState(false);
  const [doc5, setDoc5] = useState(null);
  const [doc5Name, setDoc5Name] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [progress, setProgress] = useState(0);
  const [address, setAddress] = useState('')
  // const [applicant, setApplicant] = useState('')
  const [family, setFamily] = useState([])
  const [applicantType, setApplicantType] = useState('Husband'); // Default to Husband
  const [additionalFiles, setAdditionalFiles] = useState([]);
  const [error, setError] = useState('');






  const userData = JSON.parse(localStorage.getItem('userData'));

  const userId = userData?.id;
  const username = userData?.name;
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const [filteredNations, setFilteredNations] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  // const [isToggled,toggle]=useState(toggled)


  useEffect(() => {
    console.log(userId);
    if (userId === undefined || userId === '') {

      history.push('/login');
    }
  }, []);

  useEffect(() => {
    const handlePopState = (event) => {
      const userConfirmed = window.confirm("Are you sure you want to go back?");
      if (!userConfirmed) {
        history.go(1); // Move forward in history stack to cancel back navigation
      }
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [history]);

  useEffect(() => {
    getNation();
    getAllEmirates();
    getAllFamilyVisa();
  }, []);

  // const getNation = async () => {

  //   const response = await clientService.getNationalityAll();
  //   const dataString = response.docs; // Adjust this if necessary based on actual API response structure

  //   const sortedData = dataString
  //     .map((doc) => ({ ...doc.data(), id: doc.id })) // Assuming each doc has an 'id' and other properties
  //     .sort((a, b) => (a.name || '').localeCompare(b.name || '')); // Sort alphabetically by 'name'

  //   setNation(sortedData);
  // };


  const getNation = async () => {
    const response = await clientService.getfamilyCountry()
    const dataString = response.docs; // Adjust this if necessary based on actual API response structure

    const sortedData = dataString
      .map((doc) => ({ ...doc.data(), id: doc.id })) // Assuming each doc has an 'id' and other properties
      .sort((a, b) => (a.name || '').localeCompare(b.name || '')); // Sort alphabetically by 'name'

    setNation(sortedData)
  }

  const getAllEmirates = async () => {
    const data = await clientService.getEmirates();
    setEmirate(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  const getAllFamilyVisa = async () => {
    const data = await clientService.getAllFamily();
    if (data?.docs) {
      setFamily(data?.docs?.map((doc) => ({ ...doc?.data(), id: doc?.id })));
    }

  };

  console.log(family)

  const handleNatSelect = async (e) => {
    setNationSelect(e.target.value);
  };

  const handleEmirates = async (e) => {
    e.preventDefault();
    setEmirateSelect(e.target.value);
  };

  const handleNumber = async (e) => {
    setNumber(parseInt(e.target.value));

    let array = [];

    for (let i = 0; i < e.target.value; i++) {
      array.push({ type: 'Husband', doc1: '', doc2: '' });
    }

    setAdditionalFiles(array);
  };
  console.log(additionalFiles)


  const handleToggleLoc = (value) => {
    setLocation(prevLoc => prevLoc === value ? null : value)
  }


  const handleToggleReq = (value) => {
    setReqCheck(prevLoc => prevLoc === value ? null : value)
  }


  const handleAddress = async (e) => {
    setAddress(e.target.value);
  };



  const uploadDocument = async (file) => {
    const imageRef = ref(storage, `image/${file?.name}`);
    const uploadTask = uploadBytesResumable(imageRef, file);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const prog = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(prog);
        },
        (err) => {
          console.log(err);
          reject(err);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref)
            .then((url) => {
              resolve(url);
            })
            .catch((err) => {
              console.log(err);
              reject(err);
            });
        }
      );
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)

    try {
      if (!doc1 || !doc2 || !doc3 || !doc4 || !doc5) {
        setLoading(false);
        return;
      }


      // //  to iterate over each additional files available
      // console.log(additionalFiles)
      // const additionalData = []
      // for (const file of additionalFiles) {
      //   console.log(file) //iterate variable file
      //   const additionalFilesUrls = {}  //created an object 

      //   // if additional files available 
      //   if (file.doc1) {
      //     additionalFilesUrls['pass'] = [{
      //       fileName: file.doc1?.name || '',
      //       file: await uploadDocument(file.doc1)

      //     }]
      //   }
      //   if (file.doc2) {
      //     additionalFilesUrls['marrr'] = [{
      //       fileName: file.doc2?.name || '',
      //       file: await uploadDocument(file.doc2)
      //     }]
      //   }
      //   // adding type of applicant 
      //   additionalData.push({
      //     type: file.type,
      //     ...additionalFilesUrls
      //   })

      // }

      const additionalData = additionalFiles.map((file) => ({
        type: file.type,
        pass: file.doc1 ? [{ fileName: file.doc1?.name || '', file: file.doc1Url }] : [],
        marrr: file.doc2 ? [{ fileName: file.doc2?.name || '', file: file.doc2Url }] : [],
      }));

      const currentDate = Timestamp.now();
      const data = {
        memberDetails: additionalData,
        SponserDetails: [
          {
            Passport: doc1,
            PassportfileName: doc1Name,

            visa: doc2,
            visafileName: doc2Name,

            idcard: doc3,
            idcardfileName: doc2Name,

            lc: doc4,
            lcfileName: doc4Name,

            tc: doc5,
            tcfileName: doc5Name,
          },
        ],
        nationality: {
          name: nation.find((nation) => nation.value === nationselect)?.label,
          id: nationselect,
        },
        emirates: {
          name: emirate.find((emirate) => emirate.id === emirateselect)?.name,
          id: emirateselect,
        },
        source: location,
        reqType: reqCheck,
        collectionAddress: address,
        noOfMembers: number,
        contactNumber: contact,
        requestedStatus: 'requested',
        createdDate: currentDate,
        updatedDate: currentDate,
        user: {
          name: username,
          userId: userId,
        },
      };
      console.log(data)


      const res = await addDoc(familyVisaCollectionRef, data);
      toast.success('family visa data added successfully')
      setTimeout(() => {
        history.push('/dashfamilyview')
        setLoading(false)
      }, 1000)
      setLoading(false)

    } catch (error) {
      console.log("Error occurred: ", error);
      toast.error('Error: Please try again later.');
      setLoading(false);
    }


    finally {
      setProgress(false);
    }
  };

  const handleApplicantTypeChange = (index, event, field) => {
    const newApplicant = [...applicantType];
    newApplicant[index] = event.target.value;

    setApplicantType(newApplicant);

    setAdditionalFiles((additionalFiles) => {
      const newData = [...additionalFiles]; // Create a copy of the array
      newData[index] = { ...newData[index], [field]: event.target.value }; // Update the doc1 field of the specified object
      return newData; // Update the state with the modified array
    });
  };


  const [loadingFile, setLoadingFile] = useState(null); // Track which file is loading

  const handleFileChange = async (index, e, field) => {
    let file = e.target.files[0];

    const options = {
      maxSizeMB: 0.2, // Maximum size in MB
      maxWidthOrHeight: 1920, // Maximum width or height
      useWebWorker: true, // Use web worker for faster compression
    };

    try {

      setLoadingFile(index);

      const compressedFile = await imageCompression(file, options);

      await new Promise(resolve => setTimeout(resolve, 5000));

      const uploadUrl = await uploadDocument(compressedFile);

      setAdditionalFiles((additionalFiles) => {
        const newData = [...additionalFiles];
        newData[index] = {
          ...newData[index],
          [field]: compressedFile,
          [`${field}Url`]: uploadUrl,
          [`${field}Name`]: file.name // Store file name
        };
        return newData;
      });
    } catch (error) {
      console.log('Error compressing file:', error);
    } finally {
      // Hide the loader
      setLoadingFile(null);
    }
  };


  const compressAndUploadFile = async (file) => {
    const options = {
      maxSizeMB: 0.2,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(file, options);
      const uploadUrl = await uploadDocument(compressedFile);
      return uploadUrl;
    } catch (error) {
      console.error('Error compressing or uploading file:', error);
      throw error;
    }
  };


  const handleSponsorFileChange = async (e, setDoc, setFileName, setLoading) => {
    const file = e.target.files[0];
    setLoading(true); // Show loader
    try {
      const uploadUrl = await compressAndUploadFile(file);
      setDoc(uploadUrl);
      setFileName(file.name);
    } catch (error) {
      console.error('Error handling file change:', error);
    } finally {
      setLoading(false)
    }
  };

  const handleDeleteFile = async (collectionName,docUrl, setDoc, setDocName, setLoading) => {
    setLoading(true);
    try {
      const filePath = `image/${docUrl.split("/").pop()}`; // Adjust the path according to your storage structure
      await clientService.deleteFileDocument(collectionName,docUrl, filePath);
      setDoc(null);
      setDocName("");
      console.log("file deleted successfully",filePath)
    } catch (error) {
      console.error("Error deleting file:", error);
    } finally {
      setLoading(false);
    }
  };

  const Loader = () => (
    <div className="d-flex justify-content-center align-items-center">
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );


  const renderAdditionalFields = () => {
    const fields = [];
    for (let i = 0; i < number; i++) {
      fields.push(
        <div class="card mt-3">
          <div class="card-body">
            <h5 class="card-title">Member {i + 1}</h5>
            <div key={i}>
              <Form.Group className="mb-3" controlId="formApplicantType">
                <Form.Label>Select Applicant Type</Form.Label>
                <br />
                <Form.Select
                  value={applicantType[i]}
                  onChange={(e) => handleApplicantTypeChange(i, e, 'type')}
                >
                  <option value="Husband">Husband</option>
                  <option value="Wife">Wife</option>
                  <option value="Children">Children</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Passport</Form.Label>
                <br />

                {loadingFile === i ? (
                  <Loader />
                ) : additionalFiles[i]?.doc1Url ? (
                  <div>
                    <a href={additionalFiles[i]?.doc1Url} target="_blank" rel="noopener noreferrer">
                      {additionalFiles[i]?.doc1Name || 'Uploaded File'}
                    </a>
                    <button
                    onClick={() =>
                      handleDeleteAdditionalFile(i, 'doc1', additionalFiles[i]?.doc1Url)
                    }
                    className="delete-button"
                  >
                    X
                  </button>
                  </div>) : (
                  <Form.Control
                    type="file"
                    onChange={(e) => handleFileChange(i, e, 'doc1')}
                  />
                )}
              </Form.Group>
              {applicantType[i] !== 'Children' && (
                <Form.Group className="mb-3">
                  <Form.Label>Marriage Certificate</Form.Label>
                  <br />
                  {loadingFile === i ? (
                    <Loader />
                  ) : additionalFiles[i]?.doc2Url ? (
                    <div>
                      <a href={additionalFiles[i]?.doc2Url} target="_blank" rel="noopener noreferrer">
                        {additionalFiles[i]?.doc2Name || 'Uploaded File'}
                      </a>
                      <button
                    onClick={() =>
                      handleDeleteAdditionalFile(i, 'doc2', additionalFiles[i]?.doc2Url)
                    }
                    className="delete-button"
                  >
                    X
                  </button>
                    </div>
                  ) : (
                    <Form.Control
                      type="file"
                      onChange={(e) => handleFileChange(i, e, 'doc2')}
                    />
                  )}
                </Form.Group>
              )}

              {applicantType[i] === 'Children' && (
                <Form.Group className="mb-3">
                  <Form.Label>Birth Certificate </Form.Label>
                  <br />
                  {loadingFile === i ? (
                    <Loader />
                  ) : additionalFiles[i]?.doc2Url ? (
                    <div>
                      <a href={additionalFiles[i]?.doc2Url} target="_blank" rel="noopener noreferrer">
                        {additionalFiles[i]?.doc2Name || 'Uploaded File'}
                      </a>
                    </div>
                  ) : (
                    <Form.Control
                      type="file"
                      onChange={(e) => handleFileChange(i, e, 'doc2')}
                    />
                  )}
                </Form.Group>
              )}
            </div>
          </div>
        </div>
      );
    }
    return fields;
  };

  const handleDeleteAdditionalFile = async (index, docKey, docUrl, collectionName, filePathPrefix = "image/") => {
    setLoadingFile(index);
    try {
      const filePath = `${filePathPrefix}${docUrl.split("/").pop()}`;
      await clientService.deleteFileDocument(collectionName, docUrl, filePath);
      console.log("successfully deleted file",filePath)
      setAdditionalFiles((prevFiles) => {
        const updatedFiles = [...prevFiles];
        updatedFiles[index][`${docKey}Url`] = null;
        updatedFiles[index][`${docKey}Name`] = "";
        return updatedFiles;
      });
    } catch (error) {
      console.error("Error deleting file:", error);
    } finally {
      setLoadingFile(null);
    }
  };
  

  const handlecontact = async (e) => {
    e.preventDefault()
    setContact(e.target.value)
    const phoneNumberPattern = /^\d{9}$/;

    if (!phoneNumberPattern.test(e.target.value)) {
      setError('Please enter a valid 9-digit phone number.');
    } else {
      setError('');
    }
  }

  // Handle search input change
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    const filtered = nation.filter((nat) =>
      nat.name.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setFilteredNations(filtered);
  };

  console.log(doc1)
  console.log(doc1Name)
  console.log(doc2)

  console.log(doc2Name)
  console.log(doc3)

  console.log(doc3Name)
  console.log(doc4)

  console.log(doc4Name)
  console.log(doc5)

  console.log(doc5Name)

  return (
    <React.Fragment>
      <Helmet>
        <link rel="icon" href={favIcon} />
      </Helmet>
      <OffWrap />
      <Whatsapp />

      <div className="rs-categories gray-bg style1 pt-94 pb-70 md-pt-64 md-pb-40">
        <div className="container">
          <div className="row y-middle mb-50 md-mb-30">
            <div className="col-md-6 sm-mb-30">
              <div
                className="rs-breadcrumbs breadcrumbs-overlay"
                style={{ color: 'black' }}
              >
                <div className="breadcrumbs-text black-color">
                  <ul>
                    <li>
                      <Link to="/dashboard" className="active">
                        Home
                      </Link>
                    </li>

                    <li>
                      <Link to="/dashfamilyview" className="active">
                        Family Visa
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="active">
                        Create
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="btn-part text-right sm-text-left">
                <div className="  relative text-end hidden-md">
                  <div className="expand-btn-inner search-icon">
                    <ul className="expand-items">
                      <li className="sidebarmenu-search"></li>
                      <li className="user-icon cart-inner no-border mini-cart-active">
                        <Link to="/my-account">
                          <i
                            className="fa fa-user-o"
                            aria-hidden="true"
                            style={{ fontSize: '20px' }}
                          ></i>
                        </Link>
                        <div className="woocommerce-mini-cart text-left">
                          <div className="cart-bottom-part">

                            <div className="cart-btn text-center">
                              <Link className="crt-btn btn1" to="/dashboard">
                                Home
                              </Link>
                              <Link
                                className="crt-btn btn2"
                                to="#"
                                onClick={(e) => signOut()}

                              >
                                Logout
                              </Link>
                            </div>
                          </div>
                        </div>
                      </li>

                      <li>
                        <a
                          // onClick={canvasMenuAdd}
                          id="nav-expander"
                          className="nav-expander"
                          href="#"
                        >
                          <span className="dot1"></span>
                          <span className="dot2"></span>
                          <span className="dot3"></span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="content white-bg pt-30">
              <div className="course-overview">
                <div className="inner-box">
                  <div
                    id="rs-blog"
                    className="rs-blog style1 modify1 pt-50 pb-100 md-pt-70 md-pb-70"
                  >
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-12">
                          <div
                            id="rs-about"
                            className="rs-about style1 pb-100 md-pb-70"
                          >
                            <div className="container">
                              <div className="row">
                                <div className="col-lg-11 order-last">
                                  <div className="notice-bord style1">
                                    <div className="rs-checkout orange-style pt-100 pb-100 md-pt-70 md-pb-70">
                                      <div className="container">
                                        <div className="full-grid">
                                          <form id="checkout-form">
                                            <div className="billing-fields">
                                              <div className="checkout-title">
                                                <h3>Basic details</h3>
                                              </div>
                                            </div>
                                            <div className="form-content-box">
                                              <div className="row">
                                                <div className="col-md-6 col-sm-12">
                                                  <div className="form-group">
                                                    <label>
                                                      Number Of Applicant *
                                                    </label>

                                                    <Form.Select
                                                      value={number}
                                                      onChange={handleNumber}
                                                    >
                                                      <option>
                                                        {' '}
                                                        Select Application
                                                        Number
                                                      </option>
                                                      {[...Array(20)].map(
                                                        (_, index) => (
                                                          <option
                                                            key={index}
                                                            value={index + 1}
                                                          >
                                                            {index + 1}
                                                          </option>
                                                        )
                                                      )}
                                                    </Form.Select>
                                                  </div>
                                                </div>
                                                <div className="col-md-6 col-sm-12">
                                                  <div className="form-group">
                                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                                      <div className="form-group">
                                                        <label>
                                                          Nationality *
                                                        </label>
                                                        {/* <input
                                                          type="text"
                                                          placeholder="Search Nationality"
                                                          value={searchTerm}
                                                          onChange={handleSearch}
                                                          className="form-control"
                                                        /> */}
                                                        <FormSelect
                                                          value={nationselect}
                                                          onChange={
                                                            handleNatSelect
                                                          }
                                                          className="form-control"
                                                        >
                                                          <option value="">Select Nationality</option>
                                                          {/* Render filtered or all nations based on search */}
                                                          {nation?.map((nat, index) => (
                                                            <option key={index} value={nat.value}
                                                            >{nat.label}</option>
                                                          ))}

                                                        </FormSelect>
                                                        {/* <CountryList /> */}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="col-12">
                                                  <div className="form-group">
                                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                                      <div className="form-group">
                                                        <label>Emirates*</label>
                                                        <FormSelect
                                                          value={emirateselect}
                                                          onChange={
                                                            handleEmirates
                                                          }
                                                          className="form-control"
                                                        >
                                                          <option value="">
                                                            Select Emirate
                                                          </option>
                                                          {emirate?.map(
                                                            (em, index) => (
                                                              <option
                                                                key={index}
                                                                value={em.id}
                                                              >
                                                                {em.name}
                                                              </option>
                                                            )
                                                          )}
                                                        </FormSelect>
                                                        {/* <CountryList /> */}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>

                                                <div className="col-md-12 col-sm-12 col-xs-12">
                                                  <div className="form-group">
                                                    <label>Source*</label>Inside
                                                    &nbsp;
                                                    <input
                                                      type="checkbox"
                                                      // value={inside}
                                                      checked={
                                                        location === 'Inside'
                                                      }
                                                      onChange={() =>
                                                        handleToggleLoc(
                                                          'Inside'
                                                        )
                                                      }
                                                    />
                                                    &nbsp; &nbsp; Outside &nbsp;
                                                    <input
                                                      type="checkbox"
                                                      checked={
                                                        location === 'Outside'
                                                      }
                                                      onChange={() =>
                                                        handleToggleLoc(
                                                          'Outside'
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                </div>


                                                <div className="col-md-12 col-sm-12 col-xs-12">
                                                  <div className="form-group">
                                                    <label>Source*</label>
                                                    Request for the price &nbsp;
                                                    <input
                                                      type="checkbox"
                                                      // value={priceChecked}
                                                      checked={
                                                        reqCheck ===
                                                        'Request For Pricing'
                                                      }
                                                      onChange={() =>
                                                        handleToggleReq(
                                                          'Request For Pricing'
                                                        )
                                                      }
                                                    />
                                                    &nbsp; &nbsp; Request for
                                                    Document collection &nbsp;
                                                    <input
                                                      type="checkbox"
                                                      // value={documentChecked}
                                                      checked={
                                                        reqCheck ===
                                                        'Request For Document'
                                                      }
                                                      onChange={() =>
                                                        handleToggleReq(
                                                          'Request For Document'
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                </div>

                                                {/* <div className="col-md-12 col-sm-12 col-xs-12"> */}
                                                <div className="d-flex form-group col-lg-12 mb-25">
                                                  <div className="form-control" style={{ width: '70px', maxWidth: '70px', paddingRight: '0', paddingLeft: '0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <span className="text-center">+971</span>
                                                  </div>
                                                  <input
                                                    name="hnumber"
                                                    className="form-control"
                                                    type="number"
                                                    placeholder="Enter number here"
                                                    required
                                                    value={contact}
                                                    style={{ flex: '1' }}
                                                    onChange={handlecontact}
                                                  />
                                                  {error && <div style={{ color: 'red' }}>{error}</div>}
                                                </div>
                                              </div>
                                            </div>
                                            <div className="additional-fields">
                                              <div className="form-content-box">
                                                <div className="checkout-title">
                                                  <h3>Sponsor Documents</h3>
                                                </div>
                                                <div className="row">
                                                  <div className="col-md-6 col-sm-6 col-xs-12">
                                                    <div className="form-group">
                                                      <label>Passport*</label>
                                                      {loadingDoc1 ? (
                                                        <Loader />
                                                      ) : doc1 ? (
                                                        <div>
                                                          <a href={doc1} target="_blank" rel="noopener noreferrer">
                                                            {doc1Name || 'Uploaded File'}
                                                          </a>
                                                          <button onClick={() => handleDeleteFile("familyVisaReq",doc1, setDoc1, setDoc1Name, setLoadingDoc1)}>X</button>
                                                        </div>
                                                      ) : (
                                                        <input
                                                          name="passport"
                                                          className="form-control-mod margin-bottom"
                                                          type="file"
                                                          required
                                                          onChange={(e) => handleSponsorFileChange(e, setDoc1, setDoc1Name, setLoadingDoc1)}
                                                        />
                                                      )}
                                                    </div>
                                                  </div>
                                                  <div className="col-md-6 col-sm-6 col-xs-12">
                                                    <div className="form-group">
                                                      <label>Visa*</label>
                                                      {loadingDoc2 ? (
                                                        <Loader />
                                                      ) : doc2 ? (
                                                        <div>
                                                          <a href={doc2} target="_blank" rel="noopener noreferrer">
                                                            {doc2Name || 'Uploaded File'}
                                                          </a>
                                                          <button onClick={() => handleDeleteFile("familyVisaReq",doc2, setDoc2, setDoc2Name, setLoadingDoc2)}>X</button>
                                                        </div>
                                                      ) : (
                                                        <input
                                                          name="visa"
                                                          className="form-control-mod margin-bottom"
                                                          type="file"
                                                          required
                                                          onChange={(e) => handleSponsorFileChange(e, setDoc2, setDoc2Name, setLoadingDoc2)}
                                                        />
                                                      )}
                                                    </div>
                                                  </div>
                                                  <div className="col-md-6 col-sm-6 col-xs-12">
                                                    <div className="form-group">
                                                      <label>ID CARD*</label>
                                                      {loadingDoc3 ? (
                                                        <Loader />
                                                      ) : doc3 ? (
                                                        <div>
                                                          <a href={doc3} target="_blank" rel="noopener noreferrer">
                                                            {doc3Name || 'Uploaded File'}
                                                          </a>
                                                          <button onClick={() => handleDeleteFile("familyVisaReq",doc3, setDoc3, setDoc3Name, setLoadingDoc3)}>X</button>
                                                        </div>
                                                      ) : (
                                                        <input
                                                          name="idcard"
                                                          className="form-control-mod margin-bottom"
                                                          type="file"
                                                          required
                                                          onChange={(e) => handleSponsorFileChange(e, setDoc3, setDoc3Name, setLoadingDoc3)}
                                                        />
                                                      )}
                                                    </div>
                                                  </div>
                                                  <div className="col-md-6 col-sm-6 col-xs-12">
                                                    <div className="form-group">
                                                      <label>Labour Card*</label>
                                                      {loadingDoc4 ? (
                                                        <Loader />
                                                      ) : doc4 ? (
                                                        <div>
                                                          <a href={doc4} target="_blank" rel="noopener noreferrer">
                                                            {doc4Name || 'Uploaded File'}
                                                          </a>
                                                          <button onClick={() => handleDeleteFile("familyVisaReq",doc4, setDoc4, setDoc4Name, setLoadingDoc4)}>X</button>
                                                        </div>
                                                      ) : (
                                                        <input
                                                          name="labourcard"
                                                          className="form-control-mod margin-bottom"
                                                          type="file"
                                                          required
                                                          onChange={(e) => handleSponsorFileChange(e, setDoc4, setDoc4Name, setLoadingDoc4)}
                                                        />
                                                      )}
                                                    </div>
                                                  </div>
                                                  <div className="col-md-6 col-sm-6 col-xs-12">
                                                    <div className="form-group">
                                                      <label>Tenancy Contract*</label>
                                                      {loadingDoc5 ? (
                                                        <Loader />
                                                      ) : doc5 ? (
                                                        <div>
                                                          <a href={doc5} target="_blank" rel="noopener noreferrer">
                                                            {doc5Name || 'Uploaded File'}
                                                          </a>
                                                          <button onClick={() => handleDeleteFile("familyVisaReq",doc5, setDoc5, setDoc5Name, setLoadingDoc5)}>X</button>
                                                        </div>
                                                      ) : (
                                                        <input
                                                          name="tenancycontract"
                                                          className="form-control-mod margin-bottom"
                                                          type="file"
                                                          required
                                                          onChange={(e) => handleSponsorFileChange(e, setDoc5, setDoc5Name, setLoadingDoc5)}
                                                        />
                                                      )}
                                                    </div>
                                                  </div>
                                                  <div className="row">
                                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                                      <div className="form-group">
                                                        <label>Collection Address</label>
                                                        <textarea
                                                          placeholder="Notes about your order, e.g. special notes for delivery."
                                                          value={address}
                                                          onChange={handleAddress}
                                                          required
                                                        ></textarea>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  {renderAdditionalFields()}
                                                </div>
                                              </div>
                                            </div>

                                            <div className="payment-method mt-40 md-mt-20">
                                              <div className="top-area">
                                                <div className="p-msg">
                                                  {' '}
                                                  Your personal data will be
                                                  used to process your order,
                                                  support your experience
                                                  throughout this website, and
                                                  for other purposes described
                                                  in our privacy policy.
                                                </div>
                                              </div>
                                              {loading ? (
                                                <div
                                                  className="spinner-border text-primary"
                                                  role="status"
                                                >
                                                  <span className="visually-hidden">
                                                    Loading...
                                                  </span>
                                                </div>
                                              ) : (
                                                <div className="bottom-area">
                                                  <p className="mt-14"></p>
                                                  <button
                                                    className="btn-shop orange-color"
                                                    onClick={handleSubmit}
                                                    type="submit"
                                                  >
                                                    Request
                                                  </button>
                                                </div>
                                              )}
                                            </div>
                                          </form>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
