import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';

// Image
import favIcon from '../../../assets/img/applogo.png';
import Logo from '../../../assets/img/logo/dark-logo.png';
import SiteBreadcrumb from '../../../components/Common/Breadcumb';

import Header from '../../../components/Layout/Header';
import OffWrap from '../../../components/Layout/Header/OffWrap';
import mobileLogo from '../../../assets/img/logo/logo-green.png';


import HeaderStyleSix from '../../../components/Layout/Header/HeaderStyleSix';

import bannerbg from '../../../assets/img/breadcrumbs/6.jpg';
import Footer from '../../../components/Layout/Footer/Footer';
import Whatsapp from '../Whatsapp';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from 'react-accessible-accordion';
import CountryList from './CountryList';
import { Timestamp, doc } from 'firebase/firestore';
import clientService from '../../service/clientService';
import { addDoc, collection } from 'firebase/firestore';
import { db, storage } from '../../../firebase/config';

import {
  ref,
  uploadBytes,
  getDownloadURL,
  uploadBytesResumable,
} from 'firebase/storage';
import { Form, Button, FormSelect } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Link, useHistory, useParams } from 'react-router-dom';




export default function Index() {

  const familyVisaCollectionRef = collection(db, 'familyVisaReq')

  const [nation, setNation] = useState([])
  const [nationselect, setNationSelect] = useState('')
  const [emirate, setEmirate] = useState([])
  const [emirateselect, setEmirateSelect] = useState('')
  const [number, setNumber] = useState()
  const [contact, setContact] = useState()
  const [location, setLocation] = useState(null);
  const [reqCheck, setReqCheck] = useState(null)


  const [doc1, setDoc1] = useState(null)
  const [doc1Img, setDoc1Img] = useState('');

  const [doc2, setDoc2] = useState(null)
  const [doc2Img, setDoc2Img] = useState('');

  const [doc3, setDoc3] = useState(null)
  const [doc3Img, setDoc3Img] = useState('');

  const [doc4, setDoc4] = useState(null)
  const [doc4Img, setDoc4Img] = useState('');

  const [doc5, setDoc5] = useState(null)
  const [doc5Img, setDoc5Img] = useState('');

  const [doc1Link, setDoc1Link] = useState('');
  const [doc2Link, setDoc2Link] = useState('');
  const [doc3Link, setDoc3Link] = useState('');
  const [doc4Link, setDoc4Link] = useState('');
  const [doc5Link, setDoc5Link] = useState('');





  const [showProgress, setShowProgress] = useState(false);
  const [progress, setProgress] = useState(0);
  const [address, setAddress] = useState('')
  // const [applicant, setApplicant] = useState('')
  const [family, setFamily] = useState([])
  const [applicantType, setApplicantType] = useState('Husband'); // Default to Husband
  const [additionalFiles, setAdditionalFiles] = useState([]);
  const { id } = useParams()
  // const [error, setError] = useState('');


  console.log(additionalFiles);

  const userData = JSON.parse(localStorage.getItem('userData'));

  const userId = userData.id;
  const username = userData.name;
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const [error, setError] = useState('');


  useEffect(() => {
    const handlePopState = (event) => {
      const userConfirmed = window.confirm("Are you sure you want to go back?");
      if (!userConfirmed) {
        history.go(1); // Move forward in history stack to cancel back navigation
      }
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [history]);

  useEffect(() => {
    getNation();
    getAllEmirates();
    getAllFamilyVisa();
  }, []);


  useEffect(() => {
    if (id !== undefined && id !== '') {
      editFamily()
    }
  }, [])


  useEffect(() => {
    let user = JSON.parse(localStorage.getItem('userData'));
    console.log(user);
    if (!user) {
      //   window.
      history.push('/login');
    }
  }, []);

  const signOut = () => {
    if (window.confirm('Are you sure , Do you want to Logout ?')) {
      localStorage.removeItem('userData');
      history.push('/');
    }
  };

  const handlecontact = async (e) => {
    e.preventDefault()
    setContact(e.target.value)
    const phoneNumberPattern = /^\d{9}$/;

    if (!phoneNumberPattern.test(e.target.value)) {
      setError('Please enter a valid 9-digit phone number.');
    } else {
      setError('');
    }
  }


  const editFamily = async () => {
    try {
      console.log(id)
      const res = await clientService.getFamilyOne(id)
      console.log(res.data())

      const data = res.data()
      console.log(data, "family data")
      console.log(data?.source)
      setNumber(data?.noOfMembers)
      setNationSelect(data?.nationality.id)
      setEmirateSelect(data?.emirates.id)
      setLocation(data?.source)
      console.log(data?.source)
      console.log(data?.reqType)
      setReqCheck(data?.reqType)
      setAddress(data?.collectionAddress)
      console.log(data?.collectionAddress)
      setContact(data?.contactNumber)




      const SponserDetails = data.SponserDetails[0] || {};
      setDoc1({ name: SponserDetails?.PassportfileName || '', url: SponserDetails?.Passport || '' });
      setDoc2({ name: SponserDetails?.visafileName || '', url: SponserDetails?.visa || '' });
      setDoc3({ name: SponserDetails?.idcardfileName || '', url: SponserDetails?.idcard || '' });
      setDoc4({ name: SponserDetails?.lcfileName || '', url: SponserDetails?.lc || '' });
      setDoc5({ name: SponserDetails?.tcfileName || '', url: SponserDetails?.tc || '' });



      const memberData = data.memberDetails.map(member => ({
        type: member.type,
        doc1: member.pass[0]?.fileName || '',
        doc1Url: member.pass[0]?.file || '',
        doc2: member.marrr[0]?.fileName || '',
        doc2Url: member.marrr[0]?.file || ''
      }));
      setAdditionalFiles(memberData)
      console.log(memberData)

    } catch (error) {
      console.log(error)
    }

  }
  console.log(additionalFiles)

  const getNation = async () => {
    const response = await clientService.getfamilyCountry();
    // setNation(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    const dataString = response.docs; // Adjust this if necessary based on actual API response structure

    const sortedData = dataString
      .map((doc) => ({ ...doc.data(), id: doc.id })) // Assuming each doc has an 'id' and other properties
      .sort((a, b) => (a.name || '').localeCompare(b.name || '')); // Sort alphabetically by 'name'

    setNation(sortedData);
  };

  const getAllEmirates = async () => {
    const data = await clientService.getEmirates();
    setEmirate(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  const getAllFamilyVisa = async () => {
    const data = await clientService.getAllFamily();
    setFamily(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  const handleNatSelect = async (e) => {
    setNationSelect(e.target.value);
  };

  const handleEmirates = async (e) => {
    e.preventDefault();
    setEmirateSelect(e.target.value);
  };

  const handleNumber = async (e) => {
    setNumber(parseInt(e.target.value));

    let array = [];

    for (let i = 0; i < e.target.value; i++) {
      array.push({ type: 'Husband', doc1: '', doc2: '' });
    }

    setAdditionalFiles(array);
  };
  const handleToggleLoc = (value) => {
    setLocation(prevLoc => prevLoc === value ? null : value)
  }


  const handleToggleReq = (value) => {
    setReqCheck(prevLoc => prevLoc === value ? null : value)
  }

  const handleAddress = async (e) => {
    setAddress(e.target.value);
  };

  const uploadDocument = async (doc) => {
    const imageRef = ref(storage, `image/${doc?.name}`);
    const uploadTask = uploadBytesResumable(imageRef, doc);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const prog = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(prog);
        },
        (err) => {
          console.log(err);
          reject(err);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref)
            .then((url) => {
              resolve(url);
            })
            .catch((err) => {
              console.log(err);
              reject(err);
            });
        }
      );
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    uploadDocument();

    try {

      setShowProgress(true);

      const uploadOrUseExistingUrl = async (doc, existingUrl) => {
        return doc instanceof File ? await uploadDocument(doc) : existingUrl;
      };

      let updatedDoc1Url = await uploadOrUseExistingUrl(doc1, doc1.url);
      let updatedDoc2Url = await uploadOrUseExistingUrl(doc2, doc2.url);
      let updatedDoc3Url = await uploadOrUseExistingUrl(doc3, doc3.url);
      let updatedDoc4Url = await uploadOrUseExistingUrl(doc4, doc4.url);
      let updatedDoc5Url = await uploadOrUseExistingUrl(doc5, doc5.url);

      // Check if new images are selected
      if (doc1 instanceof File) {
        updatedDoc1Url = await uploadOrUseExistingUrl(doc1, doc1.url);
      }
      if (doc2 instanceof File) {
        updatedDoc2Url = await uploadOrUseExistingUrl(doc2, doc2.url);
      }
      if (doc3 instanceof File) {
        updatedDoc3Url = await uploadOrUseExistingUrl(doc3, doc3.url);
      }
      if (doc4 instanceof File) {
        updatedDoc4Url = await uploadOrUseExistingUrl(doc4, doc4.url);
      }
      if (doc5 instanceof File) {
        updatedDoc5Url = await uploadOrUseExistingUrl(doc5, doc5.url);
      }


      //  to iterate over each additional files available
      const additionalData = await Promise.all(additionalFiles.map(async (file) => {
        const additionalFilesUrls = {};

        if (file.doc1) {
          const doc1Url = file.doc1 instanceof File ? await uploadDocument(file.doc1) : file.doc1Url;
          additionalFilesUrls['pass'] = [{
            fileName: file.doc1Name || file.doc1 || '',
            file: doc1Url
          }];
        } else {
          additionalFilesUrls['pass'] = [{
            fileName: file.doc1Name || '',
            file: file.doc1Url // Use the existing URL
          }];
        }

        if (file.doc2) {
          const doc2Url = file.doc2 instanceof File ? await uploadDocument(file.doc2) : file.doc2Url;
          additionalFilesUrls['marrr'] = [{
            fileName: file.doc2Name || file.doc2 || '',
            file: doc2Url
          }];
        } else {
          additionalFilesUrls['marrr'] = [{
            fileName: file.doc2Name || '',
            file: file.doc2Url // Use the existing URL
          }];
        }

        return {
          type: file.type,
          ...additionalFilesUrls
        };
      }));


      // Ensure additionalData is set correctly without File objects
      setAdditionalFiles(additionalData);
      console.log(additionalData);


      const currentDate = Timestamp.now();

      const data = {
        memberDetails: additionalData,
        SponserDetails: [
          {
            Passport: updatedDoc1Url,
            PassportfileName: doc1 ? doc1.name || doc1Img : doc1Img,
            visa: updatedDoc2Url,
            visafileName: doc2 ? doc2.name || doc2Img : doc2Img,
            idcard: updatedDoc3Url,
            idcardfileName: doc3 ? doc3.name || doc3Img : doc3Img,
            lc: updatedDoc4Url,
            lcfileName: doc4 ? doc4.name || doc4Img : doc4Img,
            tc: updatedDoc5Url,
            tcfileName: doc5 ? doc5.name || doc5Img : doc5Img
          },
        ],

        nationality: {
          name: nation.find((nation) => nation.value === nationselect)?.label,
          id: nationselect,
        },
        emirates: {
          name: emirate.find((emirate) => emirate.id === emirateselect)?.name,
          id: emirateselect,
        },
        source: location,
        reqType: reqCheck,
        collectionAddress: address,
        noOfMembers: number,
        contactNumber: contact,
        // requestedStatus: 'requested',
        // createdDate: currentDate,
        updatedDate: currentDate,
        user: {
          name: username,
          userId: userId,
        },
      };
      console.log(data, "edit family data")
      console.log(id)

      if (id !== undefined && id !== '') {
        const res = await clientService.updateFamily(id, data);
        console.log("updated successfully", res)
        console.log('family visa  updated successfully', res);

        toast.success('family visa  updated successfully')
        setTimeout(() => {
          window.location('/')
          history.push('/dashfamilyview')

          setLoading(false)

        }, 1000)
      }
      setLoading(false)
    } catch (error) {
      console.log("Error occurred: ", error);
      toast.error('Error: Please try again later.');
      setLoading(false);
    }
    finally {
      setProgress(false);
    }
  };

  const handleApplicantTypeChange = (index, event, field) => {
    const newApplicant = [...applicantType];
    newApplicant[index] = event.target.value;

    setApplicantType(newApplicant);

    setAdditionalFiles((additionalFiles) => {
      const newData = [...additionalFiles]; // Create a copy of the array
      newData[index] = { ...newData[index], [field]: event.target.value }; // Update the doc1 field of the specified object
      return newData; // Update the state with the modified array
    });
  };

  const handleFileChange = (index, event, docType) => {
    const file = event.target.files[0]; // Get the selected file
    setAdditionalFiles(prevState => {
      const updatedFiles = [...prevState];
      updatedFiles[index] = {
        ...updatedFiles[index],
        [docType]: file || updatedFiles[index][docType], // Keep the old file if none selected
        [`${docType}Name`]: file ? file.name : updatedFiles[index][`${docType}Name`], // Update name if new file
        [`${docType}Url`]: updatedFiles[index][`${docType}Url`] // Keep the old URL
      };
      return updatedFiles;
    });
  };




  const renderAdditionalFields = () => {
    const fields = [];
    for (let i = 0; i < number; i++) {
      const memberData = additionalFiles[i] || {};

      fields.push(
        <div className="card mt-3" key={i}>
          <div className="card-body">
            <h5 className="card-title">Member {i + 1}</h5>
            <div>
              <Form.Group className="mb-3" controlId={`formApplicantType-${i}`}>
                <Form.Label>Select Applicant Type</Form.Label>
                <br />
                <Form.Select
                  value={memberData.type || 'Husband'}
                  onChange={(e) => handleApplicantTypeChange(i, e, 'type')}
                >
                  <option value="Husband">Husband</option>
                  <option value="Wife">Wife</option>
                  <option value="Children">Children</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Passport</Form.Label>
                <br />
                <Form.Control
                  type="file"
                  onChange={(e) => handleFileChange(i, e, 'doc1')}
                />
                {memberData.doc1Url && (
                  <a href={memberData.doc1Url} download target="_blank"
                    rel="noreferrer">
                    Download Passport {memberData.doc1Name || 'File'}
                  </a>
                )}
              </Form.Group>
              {memberData.type !== 'Children' && (
                <Form.Group className="mb-3">
                  <Form.Label>Marriage Certificate</Form.Label>
                  <br />
                  <Form.Control
                    type="file"
                    onChange={(e) => handleFileChange(i, e, 'doc2')}
                  />
                  {memberData.doc2Url && (
                    <a href={memberData.doc2Url} download target="_blank"
                      rel="noreferrer">
                      Download Marriage Certificate {memberData.doc2Name || 'File'}
                    </a>
                  )}
                </Form.Group>
              )}
              {memberData.type === 'Children' && (
                <Form.Group className="mb-3">
                  <Form.Label>Birth Certificate </Form.Label>
                  <br />
                  <Form.Control
                    type="file"
                    onChange={(e) => handleFileChange(i, e, 'doc2')}
                  />
                  {memberData.doc2Url && (
                    <a href={memberData.doc2Url} download target="_blank"
                      rel="noreferrer">
                      Download Birth Certificate {memberData.doc2Name || 'File'}
                    </a>
                  )}
                </Form.Group>
              )}
            </div>
          </div>
        </div>
      );
    }
    return fields;
  };






  return (
    <React.Fragment>
      <Helmet>
        <link rel="icon" href={favIcon} />
      </Helmet>
      <OffWrap />
      <Whatsapp />

      <div className="rs-categories gray-bg style1 pt-94 pb-70 md-pt-64 md-pb-40">
        <div className="container">
          <div className="row y-middle mb-50 md-mb-30">
            <div className="col-md-6 sm-mb-30">
              <div
                className="rs-breadcrumbs breadcrumbs-overlay"
                style={{ color: 'black' }}
              >
                <div className="breadcrumbs-text black-color">
                  <ul>
                    <li>
                      <Link to="/dashboard" className="active">
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link to="/dashfamilyview" className="active">
                        Family Visa
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="active">
                        Update
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="btn-part text-right sm-text-left">
                <div className="  relative text-end hidden-md">
                  <div className="expand-btn-inner search-icon">
                    <ul className="expand-items">
                      <li className="sidebarmenu-search"></li>
                      <li className="user-icon cart-inner no-border mini-cart-active">
                        <Link to="/my-account">
                          <i
                            className="fa fa-user-o"
                            aria-hidden="true"
                            style={{ fontSize: '20px' }}
                          ></i>
                        </Link>
                        <div className="woocommerce-mini-cart text-left">
                          <div className="cart-bottom-part">
                            <div className="cart-btn text-center">
                              <Link className="crt-btn btn1" to="/dashboard">
                                Home
                              </Link>
                              <Link
                                className="crt-btn btn2"
                                to="#"
                                onClick={(e) => signOut()}
                              >
                                Logout
                              </Link>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <a
                          id="nav-expander"
                          className="nav-expander"
                          href="#">
                          <span className="dot1"></span>
                          <span className="dot2"></span>
                          <span className="dot3"></span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="content white-bg pt-30">
              <div className="course-overview">
                <div className="inner-box">
                  <div
                    id="rs-blog"
                    className="rs-blog style1 modify1 pt-50 pb-100 md-pt-70 md-pb-70"
                  >
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-12">
                          <div
                            id="rs-about"
                            className="rs-about style1 pb-100 md-pb-70"
                          >
                            <div className="container">
                              <div className="row">
                                <div className="col-lg-11 order-last">
                                  <div className="notice-bord style1">
                                    <div className="rs-checkout orange-style pt-100 pb-100 md-pt-70 md-pb-70">
                                      <div className="container">
                                        <div className="full-grid">
                                          <form id="checkout-form">
                                            <div className="billing-fields">
                                              <div className="checkout-title">
                                                <h3>Basic details</h3>
                                              </div>
                                            </div>
                                            <div className="form-content-box">
                                              <div className="row">
                                                <div className="col-md-6 col-sm-12">
                                                  <div className="form-group">
                                                    <label>
                                                      Number Of Applicant *
                                                    </label>
                                                    <Form.Select
                                                      value={number}
                                                      onChange={handleNumber}
                                                    >
                                                      <option>
                                                        {' '}
                                                        Select Application
                                                        Number
                                                      </option>
                                                      {[...Array(20)].map(
                                                        (_, index) => (
                                                          <option
                                                            key={index}
                                                            value={index + 1}
                                                          >
                                                            {index + 1}
                                                          </option>
                                                        )
                                                      )}
                                                    </Form.Select>
                                                  </div>
                                                </div>
                                                <div className="col-md-6 col-sm-12">
                                                  <div className="form-group">
                                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                                      <div className="form-group">
                                                        <label>
                                                          Nationality *
                                                        </label>
                                                        <FormSelect
                                                          value={nationselect}
                                                          onChange={
                                                            handleNatSelect
                                                          }
                                                          className="form-control"
                                                        >
                                                          <option value="">
                                                            Select Nationality
                                                          </option>

                                                          {nation?.map(
                                                            (nat, index) => (
                                                              <option
                                                                key={index}
                                                                value={nat.value}
                                                              >
                                                                {nat.label}
                                                              </option>
                                                            )
                                                          )}
                                                        </FormSelect>
                                                        {/* <CountryList /> */}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="col-12">
                                                  <div className="form-group">
                                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                                      <div className="form-group">
                                                        <label>Emirates*</label>
                                                        <FormSelect
                                                          value={emirateselect}
                                                          onChange={
                                                            handleEmirates
                                                          }
                                                          className="form-control"
                                                        >
                                                          <option value="">
                                                            Select Emirate
                                                          </option>
                                                          {emirate?.map(
                                                            (em, index) => (
                                                              <option
                                                                key={index}
                                                                value={em.id}
                                                              >
                                                                {em.name}
                                                              </option>
                                                            )
                                                          )}
                                                        </FormSelect>
                                                        {/* <CountryList /> */}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>

                                                <div className="col-md-12 col-sm-12 col-xs-12">
                                                  <div className="form-group">
                                                    <label>Source*</label>Inside
                                                    &nbsp;
                                                    <input
                                                      type="checkbox"
                                                      value={location}
                                                      checked={
                                                        location === 'Inside'
                                                      }
                                                      onChange={() =>
                                                        handleToggleLoc(
                                                          'Inside'
                                                        )
                                                      }
                                                    />
                                                    &nbsp; &nbsp; Outside &nbsp;
                                                    <input
                                                      type="checkbox"
                                                      value={location}
                                                      checked={
                                                        location === 'Outside'
                                                      }
                                                      onChange={() =>
                                                        handleToggleLoc(
                                                          'Outside'
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                                <div className="d-flex form-group col-lg-12 mb-25">
                                                  <div className="form-control" style={{ width: '70px', maxWidth: '70px', paddingRight: '0', paddingLeft: '0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <span className="text-center">+971</span>
                                                  </div>
                                                  <input
                                                    name="hnumber"
                                                    className="form-control"
                                                    type="number"
                                                    placeholder="Enter number here"
                                                    required
                                                    value={contact}
                                                    style={{ flex: '1' }}
                                                    onChange={handlecontact}
                                                  />
                                                  {error && <div style={{ color: 'red' }}>{error}</div>}
                                                </div>

                                                <div className="col-md-12 col-sm-12 col-xs-12">
                                                  <div className="form-group">
                                                    <label>Source*</label>
                                                    Request for the price &nbsp;
                                                    <input
                                                      type="checkbox"
                                                      // value={priceChecked}
                                                      checked={
                                                        reqCheck ===
                                                        'Request For Pricing'
                                                      }
                                                      onChange={() =>
                                                        handleToggleReq(
                                                          'Request For Pricing'
                                                        )
                                                      }
                                                    />
                                                    &nbsp; &nbsp; Request for
                                                    Document collection &nbsp;
                                                    <input
                                                      type="checkbox"
                                                      // value={documentChecked}
                                                      checked={
                                                        reqCheck ===
                                                        'Request For Document'
                                                      }
                                                      onChange={() =>
                                                        handleToggleReq(
                                                          'Request For Document'
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="additional-fields">
                                              <div className="form-content-box">
                                                <div className="checkout-title">
                                                  <h3>Sponsor Documents</h3>
                                                </div>
                                                <div className="row">
                                                  <div className="col-md-6 col-sm-6 col-xs-12">
                                                    <div className="form-group">
                                                      <label>Passport*</label>
                                                      <input
                                                        name="hnumber"
                                                        className="form-control-mod margin-bottom"
                                                        type="file"
                                                        placeholder="Enter number here"
                                                        required
                                                        onChange={(e) =>
                                                          setDoc1(
                                                            e.target.files[0]
                                                          )
                                                        }
                                                      />
                                                      {showProgress == true ? (
                                                        <h3>
                                                          uploaded {progress} %
                                                        </h3>
                                                      ) : (
                                                        ''
                                                      )}
                                                      {/* Display a link to download the file  */}
                                                      {doc1 && (
                                                        <div>
                                                          <span>
                                                            Uploaded File:{' '}
                                                            {doc1.name}
                                                          </span>
                                                          <a
                                                            href={doc1.url}
                                                            download
                                                            target="_blank"
                                                            rel="noreferrer"
                                                          >
                                                            Download
                                                          </a>
                                                        </div>
                                                      )}

                                                      {/* <a href={doc1Link}>Click here to download </a> */}
                                                    </div>
                                                  </div>
                                                  <div className="col-md-6 col-sm-6 col-xs-12">
                                                    <div className="form-group">
                                                      <label>Visa*</label>
                                                      <input
                                                        name="hnumber"
                                                        className="form-control-mod margin-bottom"
                                                        type="file"
                                                        // placeholder="Enter number here"
                                                        required
                                                        onChange={(e) =>
                                                          setDoc2(
                                                            e.target.files[0]
                                                          )
                                                        }
                                                        placeholder="visa"
                                                      />
                                                      {showProgress == true ? (
                                                        <h3>
                                                          uploaded {progress} %
                                                        </h3>
                                                      ) : (
                                                        ''
                                                      )}

                                                      {doc2 && (
                                                        <div>
                                                          <span>
                                                            Uploaded File:{' '}
                                                            {doc2.name}
                                                          </span>
                                                          <a
                                                            href={doc2.url}
                                                            download
                                                            target="_blank"
                                                            rel="noreferrer"
                                                          >
                                                            Download
                                                          </a>
                                                        </div>
                                                      )}

                                                      {/* <a href={doc2Link}>Click here to download </a> */}
                                                    </div>
                                                  </div>
                                                  <div className="col-md-6 col-sm-6 col-xs-12">
                                                    <div className="form-group">
                                                      <label>ID CARD*</label>
                                                      <input
                                                        name="hnumber"
                                                        className="form-control-mod margin-bottom"
                                                        type="file"
                                                        // placeholder="Enter number here"
                                                        required
                                                        onChange={(e) =>
                                                          setDoc3(
                                                            e.target.files[0]
                                                          )
                                                        }
                                                        placeholder="ID Card"
                                                      />
                                                      {showProgress == true ? (
                                                        <h3>
                                                          uploaded {progress} %
                                                        </h3>
                                                      ) : (
                                                        ''
                                                      )}

                                                      {doc3 && (
                                                        <div>
                                                          <span>
                                                            Uploaded File:{' '}
                                                            {doc3.name}
                                                          </span>
                                                          <a
                                                            href={doc3.url}
                                                            download
                                                            target="_blank"
                                                            rel="noreferrer"
                                                          >
                                                            Download
                                                          </a>
                                                        </div>
                                                      )}

                                                      {/* <a href={doc3Link}>Click here to download </a> */}
                                                    </div>
                                                  </div>
                                                  <div className="col-md-6 col-sm-6 col-xs-12">
                                                    <div className="form-group">
                                                      <label>
                                                        Labour Card*
                                                      </label>
                                                      <input
                                                        name="hnumber"
                                                        className="form-control-mod margin-bottom"
                                                        type="file"
                                                        // placeholder="Enter number here"
                                                        required
                                                        onChange={(e) =>
                                                          setDoc4(
                                                            e.target.files[0]
                                                          )
                                                        }
                                                        placeholder="Labour Contract"
                                                      />
                                                      {showProgress == true ? (
                                                        <h3>
                                                          uploaded {progress} %
                                                        </h3>
                                                      ) : (
                                                        ''
                                                      )}
                                                      {doc4 && (
                                                        <div>
                                                          <span>
                                                            Uploaded File:{' '}
                                                            {doc4.name}
                                                          </span>
                                                          <a
                                                            href={doc4.url}
                                                            download
                                                            target="_blank"
                                                            rel="noreferrer"
                                                          >
                                                            Download
                                                          </a>
                                                        </div>
                                                      )}

                                                      {/* <a href={doc4Link}>Click here to download </a> */}
                                                    </div>
                                                  </div>
                                                  <div className="col-md-6 col-sm-6 col-xs-12">
                                                    <div className="form-group">
                                                      <label>
                                                        Tenancy Contract*
                                                      </label>
                                                      <input
                                                        name="hnumber"
                                                        className="form-control-mod margin-bottom"
                                                        type="file"
                                                        placeholder="Tenancy Contract"
                                                        required
                                                        onChange={(e) =>
                                                          setDoc5(
                                                            e.target.files[0]
                                                          )
                                                        }
                                                      />
                                                      {showProgress == true ? (
                                                        <h3>
                                                          uploaded {progress} %
                                                        </h3>
                                                      ) : (
                                                        ''
                                                      )}

                                                      {doc5 && (
                                                        <div>
                                                          <span>
                                                            Uploaded File:{' '}
                                                            {doc5.name}
                                                          </span>
                                                          <a
                                                            href={doc5.url}
                                                            download
                                                            target="_blank"
                                                            rel="noreferrer"
                                                          >
                                                            Download
                                                          </a>
                                                        </div>
                                                      )}

                                                      {/* <a href={doc5Link}>Click here to download </a> */}
                                                    </div>
                                                  </div>
                                                  <div className="row">
                                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                                      <div className="form-group">
                                                        <label>
                                                          Collection Address
                                                        </label>
                                                        <textarea
                                                          placeholder="Notes about your order, e.g. special notes for delivery."
                                                          value={address}
                                                          onChange={
                                                            handleAddress
                                                          }
                                                          required
                                                        ></textarea>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  {renderAdditionalFields()}
                                                </div>
                                              </div>
                                            </div>

                                            <div className="payment-method mt-40 md-mt-20">
                                              <div className="top-area">
                                                <div className="p-msg">
                                                  {' '}
                                                  Your personal data will be
                                                  used to process your order,
                                                  support your experience
                                                  throughout this website, and
                                                  for other purposes described
                                                  in our privacy policy.
                                                </div>
                                              </div>
                                              {loading ? (
                                                <div
                                                  className="spinner-border text-primary"
                                                  role="status"
                                                >
                                                  <span className="visually-hidden">
                                                    Loading...
                                                  </span>
                                                </div>
                                              ) : (
                                                <div className="bottom-area">
                                                  <p className="mt-14"></p>
                                                  <button
                                                    className="btn-shop orange-color"
                                                    onClick={handleSubmit}
                                                    type="submit"
                                                  >
                                                    Update
                                                  </button>
                                                </div>
                                              )}
                                            </div>
                                          </form>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );

}