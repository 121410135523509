import React from 'react';
import { Link } from 'react-router-dom';

import downloadImg from '../../assets/img/download/m-app.png';

import icon1 from '../../assets/img/download/play.png';
import icon2 from '../../assets/img/download/apple.png';

const Download = () => {
  return (
    <div className="rs-download-app pt-100 pb-100 md-pt-70 md-pb-70">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 md-mb-40">
            <div className="img-part">
              <img src={downloadImg} alt="" />
            </div>
          </div>
          <div className="col-lg-6 pl-60 md-pl-14">
            <div className="sec-title3 mb-30">
              <div className="sub-title green-color">Download Mobile App</div>
              <h2 className=" title new-title">
                Explore - Any Time - Any Where; Mobile/Desktop
              </h2>
              <div className="new-desc">
                Streamline your workflow and boost your efficiency with HI P R
                O, the powerful mobile app designed to help busy professionals
                achieve more.
              </div>
            </div>
            <div className="mobile-img">
              <div className="apps-image pr-20 sm-pr-4">
                <a
                  // href="https://play.google.com/store/apps/details?id=com.hi_pro"
                  href="https://tinyurl.com/4rz8n2jn"
                  target="_blank"
                >
                  <img src={icon1} alt="playstore app link" />
                </a>
              </div>
              <div className="apps-image">
                <a target='_blank'
                  href="https://apps.apple.com/in/app/hi-p-r-o/id6502854763"
                >
                  <img src={icon2} alt="ios app link" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Download;
